@import 'responsive.scss';

$layout-gap: 1.5em;
$layout-width: 1200px;

.page-container {
    max-width: $layout-width;
    padding: $layout-gap;
    margin: 0 auto;

    &:not(.no-child-margins) {
        > *:not(:last-child) {
            margin-bottom: $layout-gap;
        }
    }
}

.row-container {
    max-width: $layout-width;
    padding: $layout-gap;
    margin: 0 auto;
}

.grid {
    display: grid;
    grid-gap: $layout-gap;
    grid-auto-columns: auto;
    align-self: start;

    > * {
        max-width: 100%;
    }

    @each $breakpoint in $breakpoint-tokens {
        @for $i from 1 through 12 {
            &.#{$breakpoint}-#{$i} {
                @include bp($breakpoint) {
                    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
                }
            }
        }
        @for $i from 1 through 12 {
            .span-#{$i} {
                grid-column: span #{$i};
            }
            .#{$breakpoint}-span-#{$i} {
                @include bp($breakpoint) {
                    grid-column: span #{$i};
                }
            }
        }
    }
}

.flex {
    &-row {
        flex-direction: row;
    }

    &-column {
        flex-direction: column;
    }

    &-row,
    &-column {
        display: flex;
    }
}

.jc-start {
    justify-content: flex-start;
}

.jc-end {
    justify-content: flex-end;
}

.jc-center {
    justify-content: center;
}

.jc-stretch {
    justify-content: stretch;
}

.jc-space-between {
    justify-content: space-between;
}

.jc-space-around {
    justify-content: space-around;
}

.jc-space-evenly {
    justify-content: space-evenly;
}

.ai-start {
    align-items: flex-start;
}

.ai-end {
    align-items: flex-end;
}

.ai-center {
    align-items: center;
}

.ai-stretch {
    align-items: stretch;
}
