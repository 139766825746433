@import 'materials.scss';

$card-padding: 2.5em;

.card {
    @include surface();
    padding: $card-padding;
}

.card-flat-outline {
    border: 1px solid var(--color-surface-border);
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
