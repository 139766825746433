@mixin shadow($depth, $direct: false) {
    @if $depth < 1 {
        box-shadow: none;
    } @else if $depth > 5 {
        @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    } @else {
        @if $direct {
            transition: box-shadow 0.15s;
            box-shadow: var(--shadow-#{$depth});
        } @else {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: inherit;
                z-index: -1;
                transition: box-shadow 0.15s;
                box-shadow: var(--shadow-#{$depth});
            }
        }
    }
}

@mixin innerShadow($depth, $direct: false) {
    @if $depth < 1 {
        box-shadow: none;
    } @else if $depth > 5 {
        @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    } @else {
        @if $direct {
            transition: box-shadow 0.15s;
            box-shadow: var(--shadow-inset-#{$depth});
        } @else {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: inherit;
                z-index: -1;
                transition: box-shadow 0.15s;
                box-shadow: var(--shadow-inset-#{$depth});
            }
        }
    }
}

@function build-shadow($depth, $light, $inset) {
    @if $depth < 1 {
        @return none;
    } @else if $depth > 5 {
        @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    } @else {
        @return build-bottom-shadow($depth, $light, $inset),
            build-top-shadow($depth, $light, $inset);
    }
}

@function build-top-shadow($depth, $light, $inset) {
    $primary-offset: nth(1.5 3 10 14 19, $depth) * if($light, 1px, 0px);
    $blur: nth(1.5 3 10 14 19, $depth) * 4px;
    $color: rgba(
        if($light, black, white),
        nth(0.12 0.16 0.19 0.25 0.3, $depth) * if($light, 0.5, 0.35)
    );
    @if $inset {
        @return inset 0 $primary-offset $blur $color;
    } @else {
        @return 0 $primary-offset $blur $color;
    }
}

@function build-bottom-shadow($depth, $light, $inset) {
    $primary-offset: nth(1.5 3 6 10 15, $depth) * if($light, 1px, 0px);
    $blur: nth(1 3 3 5 6, $depth) * 4px;
    $color: rgba(
        if($light, black, white),
        nth(0.24 0.23 0.23 0.22 0.22, $depth) * if($light, 0.25, 0.35)
    );
    @if $inset {
        @return inset 0 $primary-offset $blur $color;
    } @else {
        @return 0 $primary-offset $blur $color;
    }
}
