@import 'shadows.scss';

$mat-surface-border-radius: 0em;

@mixin surface() {
    border-radius: $mat-surface-border-radius;
    background-color: var(--color-surface);
    color: var(--color-on-surface);
    transition: all 0.15s ease;
    @include shadow(3);
}
