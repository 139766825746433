@import 'responsive';

.no-padding {
    padding: 0 !important;
}

.section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1em;

    &-title {
        flex: 1;

        .counter-badge {
            --scoped-bg-color: var(--color-surface);
            --scoped-color: var(--color-on-surface);
        }
    }

    &-actions {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    font-weight: bold;
    padding: 1em 0;
    white-space: nowrap;
    > div {
        width: 100%;
    }
    text-align: center;
    @include bp(xs) {
        text-align: left;
    }
}
