@import '~flag-icon-css/css/flag-icon.css';
@import '~material-icons/iconfont/material-icons.css';
@import '~croppie/croppie.css';
@import '~animate.css/animate.min.css';
@import 'themes.scss';
@import 'buttons.scss';
@import 'inputs.scss';
@import 'cards.scss';
@import 'layouts.scss';
@import 'responsive.scss';
@import 'typography.scss';
@import 'misc.scss';
@import 'alerts.scss';
@import 'modals.scss';

html,
body {
    margin: 0;
    height: 0;
    background-color: var(--color-background);
    color: var(--color-on-background);
    font-size: 12px;
    min-height: 100vh;
    max-width: 100vw;
    transition: background-color 0.15s ease, color 0.15s ease;
}

* {
    box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:focus,
a:active {
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: underline;
}

i.material-icons {
    font-size: 1.5em;
}
