@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,700;1,200;1,400;1,500;1,700&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    font-weight: 200;
}

.nowrap {
    word-break: keep-all;
    white-space: nowrap;
}
