$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

$breakpoint-tokens: xs sm md lg;

@mixin bp($class) {
    @if $class == xs {
        @include bp-and-below($class) {
            @content;
        }
    } @else {
        @include bp-and-above($class) {
            @content;
        }
    }
}

@mixin bp-and-above($class) {
    @if $class == xs {
        @content;
    } @else if $class == sm {
        @media only screen and (min-width: $breakpoint-sm) {
            @content;
        }
    } @else if $class == md {
        @media only screen and (min-width: $breakpoint-md) {
            @content;
        }
    } @else if $class == lg {
        @media only screen and (min-width: $breakpoint-lg) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}

@mixin bp-and-below($class) {
    @if $class == xs {
        @media only screen and (max-width: #{$breakpoint-sm - 1px}) {
            @content;
        }
    } @else if $class == sm {
        @media only screen and (max-width: #{$breakpoint-md - 1px}) {
            @content;
        }
    } @else if $class == md {
        @media only screen and (min-width: #{$breakpoint-lg - 1px}) {
            @content;
        }
    } @else if $class == lg {
        @content;
    } @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}

@each $breakpoint in $breakpoint-tokens {
    .#{$breakpoint}-hidden {
        @include bp($breakpoint) {
            display: none !important;
        }
    }
}
