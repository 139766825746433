button.btn {
    --scoped-bg-color: var(--color-surface);
    --scoped-color: var(--color-on-surface);
    --scoped-border-color: transparent;

    font-size: 1em;
    font-weight: 500;
    outline: none;
    transition: all 0.15s ease;
    border: 2px solid var(--scoped-border-color);
    padding: 0.7em 1em;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--scoped-bg-color);
    color: var(--scoped-color);
    min-height: 42px;
    border-radius: 0;

    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            --scoped-bg-color: var(--color-surface-mute);
            transform: translateY(-1px);
        }

        &:active {
            transform: translateY(1px);
        }
    }

    &.btn-primary {
        --scoped-bg-color: var(--color-primary);
        --scoped-color: var(--color-on-primary);

        &:not(:disabled):hover {
            --scoped-bg-color: var(--color-primary-hover);
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    &.btn-secondary {
        --scoped-bg-color: var(--color-secondary);
        --scoped-color: var(--color-on-secondary);
        &:not(:disabled):hover {
            --scoped-bg-color: var(--color-secondary-hover);
        }

        body.dark & {
            --scoped-border-color: var(--color-on-surface);
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    &.btn-caution {
        --scoped-bg-color: var(--color-caution);
        --scoped-color: var(--color-on-caution);

        &:not(:disabled):hover {
            --scoped-bg-color: var(--color-caution-hover);
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    &.btn-transparent {
        --scoped-bg-color: transparent;
        --scoped-color: var(--color-on-surface);
        border: 1px solid var(--color-surface-border);
        &:not(:disabled):hover {
            --scoped-bg-color: var(--color-on-secondary);
        }
        &:disabled {
            opacity: 0.5;
        }
    }

    &.btn-unavailable {
        --scoped-bg-color: var(--color-button-unavailable);
        --scoped-color: var(--color-on-button-unavailable);
    }

}
