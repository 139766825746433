@import 'materials.scss';
@import 'responsive.scss';
@import 'cards.scss';

.modal {
    @include surface();
    width: 100%;
    overflow: hidden;
    position: relative;

    &-bg {
        width: 100%;
        height: 100%;
        max-height: 100vh;
        backdrop-filter: blur(10px);
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        pointer-events: all;
        padding: 1em;
    }

    &-md {
        max-width: $breakpoint-sm;
    }

    &-sm {
        max-width: $breakpoint-sm / 2;
    }

    &-header,
    &-content,
    &-actions {
        position: relative;
        padding: $card-padding;

        &:not(:first-child) {
            padding-top: $card-padding / 2;
        }

        &:not(:last-child) {
            padding-bottom: $card-padding / 2;
        }
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include bp(xs) {
            flex-direction: column;
            align-items: flex-start;
        }

        h1:first-child {
            flex-shrink: 0;
        }

        @include bp(sm) {
            *:last-child {
                text-align: right;
            }
            > *:not(:first-child) {
                margin-left: 1em;
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
    }

    &-actions {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-end;
        button:not(:first-child) {
            margin-left: 0.5em;
        }
    }

    &-status-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .error-icon {
            font-size: 7em;
            color: var(--color-caution);
        }

        .success-icon {
            font-size: 7em;
            color: var(--color-success);
        }

        .status-message {
            margin-top: 2em;
            font-weight: 500;
        }
    }
}
