.alert {
    width: 100%;
    display: block;
    padding: 1em;
    border-radius: 0.25em;
    border-left: 5px solid rgba(black, 0.25);
    background-color: var(--color-alert-default);
    color: var(--color-on-alert-default);

    &-success {
        background-color: var(--color-alert-success);
        color: var(--color-on-alert-success);
    }

    &-warning {
        background-color: var(--color-alert-warning);
        color: var(--color-on-alert-warning);
    }

    &-error {
        background-color: var(--color-alert-error);
        color: var(--color-on-alert-error);
    }
}
