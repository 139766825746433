@import 'node_modules/sass-material-colors/sass/sass-material-colors';
@import 'shadows.scss';

@mixin baseTheme() {
    --color-primary: #{material-color('amber', '600')};
    --color-primary-dark: #{material-color('amber', '800')};
    --color-primary-hover: #{material-color('amber', '500')};
    --color-on-primary: white;

    --color-secondary: #{material-color('grey', '900')};
    --color-secondary-dark: black;
    --color-secondary-hover: #{material-color('grey', '800')};
    --color-on-secondary: white;

    --color-input-border-inactive: #{material-color('grey', '500')};
    --color-input-border-active: var(--color-primary);

    --color-button-unavailable: #{material-color('grey', '500')};
    --color-on-button-unavailable: white;

    --color-alert-default: var(--color-primary-dark);
    --color-on-alert-default: var(--color-on-primary);
    --color-alert-info: var(--color-info);
    --color-on-alert-info: var(--color-on-info);
    --color-alert-success: var(--color-success);
    --color-on-alert-success: var(--color-on-success);
    --color-alert-warning: var(--color-warning);
    --color-on-alert-warning: var(--color-on-warning);
    --color-alert-error: var(--color-caution);
    --color-on-alert-error: var(--color-on-caution);
}

@mixin lightTheme() {
    --theme-name: 'light';

    --color-background: #{material-color('grey', '100')};
    --color-on-background: #{material-color('grey', '900')};
    --color-on-background-mute: #{material-color('grey', '400')};

    --color-surface: #{white};
    --color-surface-mute: #{material-color('grey', '200')};
    --color-surface-border: #{material-color('grey', '300')};
    --color-on-surface: #{material-color('grey', '900')};
    --color-on-surface-mute: #{material-color('grey', '400')};

    @for $i from 1 through 5 {
        --shadow-#{$i}: #{build-shadow($i, true, false)};
        --shadow-inset-#{$i}: #{build-shadow($i, true, true)};
    }

    --color-info: #{material-color('blue', '500')};
    --color-info-hover: #{material-color('blue', '400')};
    --color-on-info: white;

    --color-caution: #{material-color('red', '600')};
    --color-caution-hover: #{material-color('red', '500')};
    --color-on-caution: white;

    --color-warning: #{material-color('amber', '600')};
    --color-warning-hover: #{material-color('amber', '500')};
    --color-on-warning: #{material-color('grey', '900')};

    --color-success: #{material-color('light-green', '500')};
    --color-success-hover: #{material-color('light-green', '400')};
    --color-on-success: white;
}

@mixin darkTheme() {
    --theme-name: 'dark';

    --color-background: #{black};
    --color-on-background: #{material-color('grey', '100')};
    --color-on-background-mute: #{material-color('grey', '400')};

    --color-surface: #{material-color('grey', '900')};
    --color-surface-mute: #{material-color('grey', '800')};
    --color-on-surface: #{material-color('grey', '100')};
    --color-on-surface-mute: #{material-color('grey', '400')};
    --color-surface-border: #{material-color('grey', '800')};

    @for $i from 1 through 5 {
        --shadow-#{$i}: #{build-shadow($i, false, false)};
        --shadow-inset-#{$i}: #{build-shadow($i, false, true)};
    }

    --color-info: #{material-color('blue', '900')};
    --color-info-hover: #{material-color('blue', '800')};
    --color-on-info: white;

    --color-caution: #{material-color('red', '900')};
    --color-caution-hover: #{material-color('red', '800')};
    --color-on-caution: white;

    --color-warning: #{material-color('amber', '900')};
    --color-warning-hover: #{material-color('amber', '800')};
    --color-on-warning: white;

    --color-success: #{material-color('light-green', '900')};
    --color-success-hover: #{material-color('light-green', '800')};
    --color-on-success: white;
}

// Selectors
body {
    @include baseTheme();
}

body.dark {
    @include darkTheme();
}

body:not(.dark) {
    @include lightTheme();
}
