@import 'themes';

input[type='text'],
input[type='password'],
textarea {
    &.input {
        padding: 0.5em 1em;
        border: none;
        outline: none;
        border-bottom: 2px solid var(--color-input-border-inactive);
        border-radius: 0;
        transition: all 0.15s ease;
        background: var(--color-surface-mute);
        color: var(--color-on-surface);

        &::placeholder {
            color: var(--color-on-surface-mute);
        }

        &:focus {
            border-color: var(--color-input-border-active);
        }

        &-large {
            font-size: 1.5em;
        }
    }
}
